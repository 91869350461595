import { useMemo } from "react";
import { getSMSProvider, ReceiverDeliveryStatus, ReceiverListObj } from "./MsgModel";
import { Column, ColumnInstance, usePagination, useTable } from "react-table";
import { useTranslation } from "react-i18next";
import MsgStatusComponent from "./MsgStatusComponent";
import Pagination from "../../UI/Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/pro-light-svg-icons";
import { faPaperPlane } from "@fortawesome/pro-regular-svg-icons";
import { RIListPaginationConfig } from "./PreviewMessage";
import CircularLoading from "../../UI/CircularLoading";
import i18next from "i18next";
import Tooltip from "../../UI/Tooltip";

export type RIListSortConfig = {
  columnId: string;
  sortKey: string;
  sortType: "Ascending" | "Descending";
};

interface Props {
  data: ReceiverListObj[];
  totalDataCount: number;
  listIsLoading: boolean;
  paginationConfig: RIListPaginationConfig;
  setPaginationConfig: React.Dispatch<React.SetStateAction<RIListPaginationConfig>>;
  onResendClick: (receiverUniqueKey: string) => void;
  setSortConfig: React.Dispatch<React.SetStateAction<RIListSortConfig | undefined>>;
  sortConfig?: RIListSortConfig;
}

export default function ReceiverTable(props: Props) {
  const { t } = useTranslation();

  const columns: Column<ReceiverListObj>[] = useMemo(
    () => [
      {
        header: t("receiver"),
        accessor: "receiver",
        Header: ({ column }) =>
          Header({
            column: column,
            sortConfig: props.sortConfig,
            setSortConfig: props.setSortConfig,
          }),
      },
      {
        header: t("language_of_received_language"),
        accessor: "language",
        Header: ({ column }) =>
          Header({
            column: column,
            sortConfig: props.sortConfig,
            setSortConfig: props.setSortConfig,
          }),
      },
      {
        header: t("provider"),
        accessor: "smsProvider",
        Header: ({ column }) =>
          Header({
            column: column,
            isNotSortable: true,
          }),
        Cell: ({ value }) => <label>{getSMSProvider(value)}</label>,
      },
      {
        header: t("status"),
        accessor: "status",
        Cell: ({ value }) => <MsgStatusComponent key={value} msgStatus={value} />,
        Header: ({ column }) =>
          Header({
            column: column,
            sortConfig: props.sortConfig,
            setSortConfig: props.setSortConfig,
          }),
      },
      {
        header: t("send_time"),
        accessor: "sendTime",
        Cell: ({ value }) => <label>{value.translateDigits()}</label>,
        Header: ({ column }) =>
          Header({
            column: column,
            sortConfig: props.sortConfig,
            setSortConfig: props.setSortConfig,
          }),
      },
      {
        header: "",
        accessor: "uniqueKey",
        Cell: ({ value, row }) => (
          <ResendComponent
            hasResend={
              row.original.status === ReceiverDeliveryStatus.UD || row.original.status === ReceiverDeliveryStatus.FD
            }
            onResendClicked={() => props.onResendClick(row.original.uniqueKey)}
          />
        ),
      },
    ],
    [t, props.sortConfig]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable<ReceiverListObj>(
    {
      columns: columns,
      data: props.data,
    },
    usePagination
  );

  return (
    <div className="flex w-full h-[480px] flex-col text-black41 justify-between">
      {props.listIsLoading ? (
        <div className="flex w-full h-full items-center justify-center">
          <CircularLoading />
        </div>
      ) : (
        <div className="overflow-auto h-[480px]">
          <table {...getTableProps()} className="w-full">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, index) => (
                    <th
                      className={`sticky-header bg-white td-firefox text-sm text-gray-41 border-b-[1px] py-[20px]`}
                      {...column.getHeaderProps()}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, index) => {
                prepareRow(row);

                return (
                  <tr className="text-sm" key={index}>
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps()}
                        className="py-4 whitespace-nowrap"
                        style={cell.column.id === "language" ? { textTransform: "capitalize" } : {}}
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      <Pagination
        dropdownKeys={["5", "10", "20"]}
        previousPage={() =>
          props.setPaginationConfig((preValue) => ({
            ...preValue,
            pageIndex: preValue.pageIndex - 1,
          }))
        }
        nextPage={() =>
          props.setPaginationConfig((preValue) => ({
            ...preValue,
            pageIndex: preValue.pageIndex + 1,
          }))
        }
        setPageSize={(pageSize) =>
          props.setPaginationConfig((preValue) => ({
            ...preValue,
            pageSize: pageSize,
          }))
        }
        gotoPage={(_pageNum) =>
          props.setPaginationConfig((preValue) => ({
            ...preValue,
            pageIndex: _pageNum as number,
          }))
        }
        pageCount={Math.ceil(props.totalDataCount / props.paginationConfig.pageSize)}
        pageIndex={props.paginationConfig.pageIndex}
        pageSize={props.paginationConfig.pageSize}
        pageLength={props.data.length}
        totalLength={props.totalDataCount}
      />
    </div>
  );
}

interface HeaderProps {
  column: ColumnInstance<ReceiverListObj>;
  sortConfig?: RIListSortConfig;
  setSortConfig?: React.Dispatch<React.SetStateAction<RIListSortConfig | undefined>>;
  isNotSortable?: boolean;
}

function Header(props: HeaderProps) {
  const mapColumnNameToColumnSortKey = (columnId: string) => {
    if (columnId === "sendTime") return "send_date";

    return columnId.toLowerCase();
  };

  return (
    <div className="flex items-center">
      <h3>{props.column.render("header")}</h3>
      {!props.isNotSortable && (
        <div className={`flex flex-col ${i18next.language === "ar" ? "mr-4" : "ml-4"}`}>
          <FontAwesomeIcon
            className={`text-sm cursor-pointer ${
              props.sortConfig?.columnId === props.column.id && props.sortConfig?.sortType === "Ascending"
                ? "text-black41"
                : "text-gray-df"
            }`}
            icon={faAngleUp}
            onClick={() =>
              props.setSortConfig?.({
                columnId: props.column.id,
                sortKey: mapColumnNameToColumnSortKey(props.column.id),
                sortType: "Ascending",
              })
            }
          />
          <FontAwesomeIcon
            className={`text-sm cursor-pointer ${
              props.sortConfig?.columnId === props.column.id && props.sortConfig?.sortType === "Descending"
                ? "text-black41"
                : "text-gray-df"
            }`}
            icon={faAngleDown}
            onClick={() =>
              props.setSortConfig?.({
                columnId: props.column.id,
                sortKey: mapColumnNameToColumnSortKey(props.column.id),
                sortType: "Descending",
              })
            }
          />
        </div>
      )}
    </div>
  );
}

interface ResendComponentProps {
  hasResend: boolean;
  onResendClicked: () => void;
}

function ResendComponent(props: ResendComponentProps) {
  const { t } = useTranslation();

  return props.hasResend ? (
    <Tooltip
      onClick={props.onResendClicked}
      containerClassname="w-fit cursor-pointer p-[6px] hover:bg-gray-f2 rounded-[3px]"
      content={t("resend")}
      position={{
        x: 10,
        y: 10,
      }}
    >
      <FontAwesomeIcon icon={faPaperPlane} className="text-sm text-blue-primary" />
    </Tooltip>
  ) : (
    <></>
  );
}
