import { MessageStatus, ReceiverDeliveryStatus, SMSProvider } from "../components/main/messaging/MsgModel";

export const extractMessageStatuses = (msgFilterConfig?: any): MessageStatus[] => {
  const messageStatuses: MessageStatus[] = [];
  if (msgFilterConfig?.statuses) {
    msgFilterConfig.statuses.forEach((st: any) => {
      if (st.id) {
        const targetEnum = (Object.entries(MessageStatus) as [keyof typeof MessageStatus, string][]).find(
          ([_, enumValue]) => enumValue === st.id
        )?.[0] as MessageStatus;

        if (targetEnum) messageStatuses.push(targetEnum);
      }
    });
  }
  return messageStatuses;
};

export const extractRcvDeliveryStatuses = (msgFilterConfig?: any): ReceiverDeliveryStatus[] => {
  const messageStatuses: ReceiverDeliveryStatus[] = [];
  if (msgFilterConfig?.statuses) {
    msgFilterConfig.statuses.forEach((st: any) => {
      if (st.id) {
        const targetEnum = (
          Object.entries(ReceiverDeliveryStatus) as [keyof typeof ReceiverDeliveryStatus, string][]
        ).find(([_, enumValue]) => enumValue === st.id)?.[0] as ReceiverDeliveryStatus;

        if (targetEnum) messageStatuses.push(targetEnum);
      }
    });
  }
  return messageStatuses;
};

export const extractSmsProvider = (smsProviderArray: Array<any>): SMSProvider[] => {
  const smsProviders: SMSProvider[] = [];

  smsProviderArray.forEach((st: any) => {
    if (st.id) {
      const targetEnum = (Object.entries(SMSProvider) as [keyof typeof SMSProvider, string][]).find(
        ([_, enumValue]) => enumValue === st.id
      )?.[0] as SMSProvider;

      if (targetEnum) smsProviders.push(targetEnum);
    }
  });

  return smsProviders;
};
