import React from "react";
import { useTranslation } from "react-i18next";

import { StatusBadgeTypes } from "../../utils/Constants";

const StatusBadge: React.FC<{
  type: StatusBadgeTypes;
}> = (props) => {
  const { type } = props;

  const { t } = useTranslation();

  let stringId, color, bgColor;
  switch (type) {
    case StatusBadgeTypes.ACTIVE:
      stringId = "active";
      color = "text-green-primary";
      bgColor = "bg-green-primary33";
      break;
    case StatusBadgeTypes.INACTIVE:
      stringId = "inactive";
      color = "text-red-primary";
      bgColor = "bg-red-primary40";
      break;
    case StatusBadgeTypes.PENDING:
      stringId = "pending";
      color = "text-orange-primary";
      bgColor = "bg-orange-primary33";
      break;
    case StatusBadgeTypes.SUBSCRIBE:
      stringId = "sb";
      color = "text-green-primary";
      bgColor = "bg-green-primary33";
      break;
    case StatusBadgeTypes.UNSUBSCRIBE:
      stringId = "us";
      color = "text-red-primary";
      bgColor = "bg-red-primary40";
      break;
    case StatusBadgeTypes.RESPONDED:
      stringId = "responded";
      color = "text-green-primary";
      bgColor = "bg-green-primary33";
      break;
    case StatusBadgeTypes.MOVED:
      stringId = "moved";
      color = "text-orange-primary";
      bgColor = "bg-orange-primary33";
      break;
  }

  return <p className={`p-2 rounded capitalize inline ${color} ${bgColor}`}>{t(stringId || "")}</p>;
};

export default StatusBadge;
