import Pagination from "../../UI/Pagination";
import { getSMSProvider, ListMessageObj, MessageStatus, RepeatFrequency } from "./MsgModel";
import i18next from "i18next";
import { useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/pro-light-svg-icons";
import ReceiverCell from "./message_table_cells/ReceiverCell";
import MessageStatusCell from "./message_table_cells/MessageStatusCell";
import SendTimeCell from "./message_table_cells/SendTimeCell";
import NoteCell from "./message_table_cells/NoteCell";
import MsgStatusComponent from "./MsgStatusComponent";
import { PaginationConfig } from "./MessageManagement";
import CircularLoading from "../../UI/CircularLoading";
import { Column, usePagination, useTable } from "react-table";
import { useTranslation } from "react-i18next";

export type MsgListSortConfig = {
  columnId: string;
  sortKey: string;
  sortType: "Ascending" | "Descending";
};

interface Props {
  data: ListMessageObj[];
  pageCount: number;
  totalDataCount: number;
  listIsLoading: boolean;
  setPaginationConfig: React.Dispatch<React.SetStateAction<PaginationConfig>>;
  pagiantionConfig: PaginationConfig;
  sortChanged: (sortConfig: MsgListSortConfig | undefined) => void;
  onStopClick: (messageUniqueKey: string) => void;
  onPreviewClick: (messageUniqueKey: string) => void;
}

export default function MessagesTable(props: Props) {
  const [sortConfig, setSortConfig] = useState<MsgListSortConfig | undefined>(undefined);
  const { t } = useTranslation();

  const columns: Column<ListMessageObj>[] = useMemo(
    () => [
      {
        header: t("receiver"),
        accessor: "recivers",
        Cell: ({ value, row }) => ReceiverCell({ receivers: value, futureReceivers: row.original.futureReceivers }),
      },
      {
        header: t("message"),
        accessor: "message",
        Cell: ({ value }) => <label>{value.truncate(30)}</label>,
      },
      {
        header: t("provider"),
        accessor: "smsProvider",
        Cell: ({ value }) => <label>{getSMSProvider(value)}</label>,
      },
      {
        header: t("message_status"),
        accessor: "msgStatus",
        Cell: ({ value }) => <MsgStatusComponent msgStatus={value} />,
      },
      {
        header: t("delivery_status"),
        accessor: "receiversStatuses",
        Cell: ({ value, row }) =>
          MessageStatusCell({
            receiversInfoStats: value.length > 0 ? value : [],
            hasRepeat: row.original.repeatFrequency > 0 && row.original.repeatFrequencyType !== RepeatFrequency.NO,
            repeatFrequncyType: row.original.repeatFrequencyType,
          }),
      },
      {
        header: t("send_time"),
        accessor: "sendTime",
        Cell: ({ value, row }) =>
          SendTimeCell({ sendTime: value, isStopped: row.original.msgStatus === MessageStatus.CD }),
      },
      {
        header: t("note"),
        accessor: "note",
        Cell: ({ value, row }) =>
          NoteCell({
            note: value,
            messageStatus: row.original.msgStatus,
            onStopClick: () => props.onStopClick(row.original.uniqueKey),
            onPreviewClick: () => props.onPreviewClick(row.original.uniqueKey),
          }),
      },
    ],
    [t, props.pagiantionConfig]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable<ListMessageObj>(
    {
      columns: columns,
      data: props.data,
    },
    usePagination
  );

  useEffect(() => {
    props.sortChanged(sortConfig);
  }, [sortConfig]);

  const mapColumnNameToColumnSortKey = (columnName: string) => {
    if (columnName === "sendTime") return "send_date";

    return columnName.toLowerCase();
  };

  return (
    <div className="flex w-full h-[480px] flex-col text-black41 justify-between">
      {props.listIsLoading ? (
        <div className="flex w-full h-full items-center justify-center">
          <CircularLoading />
        </div>
      ) : (
        <div className="h-[480px] overflow-auto">
          <table {...getTableProps()} className="w-full">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, index, row) => (
                    // Add the sorting props to control sorting. For this example
                    // we can add them into the header props
                    <th
                      className={`sticky-header bg-white td-firefox text-sm text-gray-41 border-b-[1px] py-[20px]`}
                      {...column.getHeaderProps()}
                    >
                      <div
                        // onClick={() => props.setPaginationConfig((preValue) => ({ ...preValue, pageIndex: 0 }))}
                        className="flex items-center"
                      >
                        <h3>{column.render("header")}</h3>
                        {column.id === "sendTime" && (
                          <div className={`flex flex-col ${i18next.language === "ar" ? "mr-4" : "ml-4"} `}>
                            <FontAwesomeIcon
                              className={`text-sm cursor-pointer ${
                                sortConfig?.columnId === column.id && sortConfig?.sortType === "Ascending"
                                  ? "text-black41"
                                  : "text-gray-df"
                              }`}
                              icon={faAngleUp}
                              onClick={() => {
                                setSortConfig({
                                  columnId: column.id,
                                  sortKey: mapColumnNameToColumnSortKey(column.id),
                                  sortType: "Ascending",
                                });
                              }}
                            />
                            <FontAwesomeIcon
                              className={`text-sm cursor-pointer ${
                                sortConfig?.columnId === column.id && sortConfig?.sortType === "Descending"
                                  ? "text-black41"
                                  : "text-gray-df"
                              }`}
                              icon={faAngleDown}
                              onClick={() => {
                                setSortConfig({
                                  columnId: column.id,
                                  sortKey: mapColumnNameToColumnSortKey(column.id),
                                  sortType: "Descending",
                                });
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);

                return (
                  <tr {...row.getRowProps()} className="text-sm">
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()} className="py-4 whitespace-nowrap">
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      <Pagination
        dropdownKeys={["5", "10", "20"]}
        previousPage={() =>
          props.setPaginationConfig((preValue) => ({ ...preValue, pageIndex: preValue.pageIndex - 1 }))
        }
        nextPage={() => props.setPaginationConfig((preValue) => ({ ...preValue, pageIndex: preValue.pageIndex + 1 }))}
        setPageSize={(pageSize) => props.setPaginationConfig((preValue) => ({ ...preValue, pageSize: pageSize }))}
        gotoPage={(_pageNum) =>
          props.setPaginationConfig((preValue) => ({ ...preValue, pageIndex: _pageNum as number }))
        }
        pageCount={props.pageCount}
        pageIndex={props.pagiantionConfig.pageIndex}
        pageSize={props.pagiantionConfig.pageSize}
        pageLength={props.data.length}
        totalLength={props.totalDataCount}
      />
    </div>
  );
}
