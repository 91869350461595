import { getSMSProviderByValue, SMSProvider } from "./MsgModel";
import Dropdown, { DropdownType } from "../../UI/Dropdown";
import { useTranslation } from "react-i18next";
import { isLebanonVersion } from "../../../App";

interface Props {
  onProviderSelected: (provider: SMSProvider) => void;
  currentSelectedProvider: SMSProvider;
}

export default function MessageSenderSelector(props: Props) {
  const { t } = useTranslation();

  return (
    <div className="flex items-center ">
      <label className="text-gray-41 mr-2 ml-0 rtl:mr-0 rtl:ml-2">{`${t("from").capitalize()}:`}</label>
      <Dropdown
        keys={isLebanonVersion ? [SMSProvider.TO] : [SMSProvider.TO, SMSProvider.AT]}
        onOptionSelected={(key) => props.onProviderSelected(getSMSProviderByValue(key))}
        selectedKey={
          SMSProvider[props.currentSelectedProvider?.toUpperCase() as unknown as keyof typeof SMSProvider] ??
          SMSProvider.TO
        }
        type={DropdownType.PRIMARY}
        capitalize={true}
      />
    </div>
  );
}
