import React from "react";
import { useTranslation } from "react-i18next";

import Button from "./Button";
import { ButtonTypes } from "../../utils/Constants";
import i18next from "i18next";

const LastConfirmWarningModal: React.FC<{
  onCancel: () => void;
  onConfirm: () => void;
  onBackdrop: () => void;
  titleKey: string;
  messageKey: string;
  confirmButtonLabelKey: string;
}> = ({
  onCancel,
  onConfirm,
  onBackdrop,
  titleKey,
  messageKey,
  confirmButtonLabelKey,
}) => {
    const { t } = useTranslation();

    return (
      <div
        data-testid="logout-modal"
        className="backdrop"
        onClick={() => onBackdrop()}
      >
        <section
          dir={i18next.language === "ar" ? "rtl" : "ltr"}
          className="bg-white shadow-md p-6 cursor-auto w-[90%] max-w-[492px] 480:w-[100%] rounded overflow-hidden"
          onClick={(e) => e.stopPropagation()}
        >
          <h3 className="capitalize text-black27 text-lg font-semibold">
            {t(titleKey)}
          </h3>
          <p
            className="text-sm text-black41 first-letter:uppercase mt-10 break-words whitespace-normal"
          >
            {t(messageKey)}
          </p>
          <div className="flex mt-10 justify-end">
            <Button
              type={ButtonTypes.CANCEL}
              onClick={() => onCancel()}
              className="mx-3"
            >
              {t("cancel")}
            </Button>
            <Button type={ButtonTypes.ERROR} onClick={() => onConfirm()}>
              {t(confirmButtonLabelKey)}
            </Button>
          </div>
        </section>
      </div>
    );
  };

export default LastConfirmWarningModal;
