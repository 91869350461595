import useAPI from "../../hooks/useAPI";

const StaffAPI = () => {
  const { sendRequest } = useAPI();

  const getStaff = (staffPreccessResponse: (response: Response) => void, url: string) => {
    sendRequest(
      {
        url: url,
      },
      staffPreccessResponse
    );
  };

  const exportStaff = (exportPreccessResponse: (response: Response) => void, slug: string, queryParams: string) => {
    sendRequest(
      {
        url: `clinics/staffs/export/${queryParams}`,
        method: "POST",
        body: JSON.stringify([{ slug }]),
      },
      exportPreccessResponse
    );
  };

  const addStaff = (
    exportPreccessResponse: (response: Response) => void,
    name: string,
    lastName: string,
    phone: string,
    email: string,
    language: string,
    isClinicAdmin: boolean,
    slug: string
  ) => {
    sendRequest(
      {
        url: `clinics/${slug}/staffs/`,
        method: "POST",
        body: JSON.stringify({
          first_name: name,
          last_name: lastName,
          phone_number: phone,
          email: email,
          preferred_language: language,
          is_clinic_admin: isClinicAdmin,
        }),
      },
      exportPreccessResponse
    );
  };

  const editStaff = (
    exportPreccessResponse: (response: Response) => void,
    name: string,
    lastName: string,
    phone: string,
    email: string,
    language: string,
    isClinicAdmin: boolean,
    slug: string,
    _editStaffSlug: string
  ) => {
    sendRequest(
      {
        url: `clinics/${slug}/staffs/${_editStaffSlug}/`,
        method: "PUT",
        body: JSON.stringify({
          first_name: name,
          last_name: lastName,
          phone_number: phone,
          email: email,
          preferred_language: language,
          is_clinic_admin: isClinicAdmin,
        }),
      },

      exportPreccessResponse
    );
  };

  const updateStatus = (
    staffPreccessResponse: (response: Response) => void,
    slug: string,
    _status: string,
    _slugStaff: string
  ) => {
    sendRequest(
      {
        url: `clinics/${slug}/staffs/${_slugStaff}/`,
        method: "PATCH",
        body: JSON.stringify({
          status: _status,
        }),
      },
      staffPreccessResponse
    );
  };

  const moveStaff = (
    staffPreccessResponse: (response: Response) => void,
    clinicSlug: string,
    staffSlug: string,
    newClinicSlug: string,
  ) => {
    sendRequest(
      {
        url: `clinics/${clinicSlug}/staffs/${staffSlug}/move/`,
        method: "PUT",
        body: JSON.stringify({
          new_clinic_slug: newClinicSlug,
        }),
      },
      staffPreccessResponse
    );
  };

  return { getStaff, exportStaff, addStaff, editStaff, updateStatus, moveStaff };
};

export default StaffAPI;
