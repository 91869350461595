import IdValueType from "../../../types/IdValueType";

export const CIMA_PHONE = "+43 670 3082719";

export enum MessageStatus {
  NO = "Initial",
  QD = "Queued",
  ST = "Sent",
  SD = "Scheduled",
  CD = "Canceled",
  DS = "Done Scheduled",
  NN = "None",
  // DD = "Delivered",
  // UD = "Undelivered",
  // FD = "Failed",
  // SG = "Sending",
  // FI = "Future", // This field only used for future Receivers.
}

export enum ReceiverDeliveryStatus {
  NO = "Initial",
  QD = "Queued",
  SG = "Sending",
  ST = "Sent",
  SD = "Scheduled",
  CD = "Canceled",
  DD = "Delivered",
  UD = "Undelivered",
  FD = "Failed",
  FI = "Future", // This field only used for future Receivers.
}

export enum CimaTimezone {
  RWA = "Africa/Kigali", // (for Rwanda)
  CMR = "Africa/Douala", // (for Cameroon)
  LBN = "Asia/Beirut", //  (for Lebanon)
}

export enum SMSProvider {
  TO = "Twilio",
  AT = "Africa's Talking",
}

export const getSMSProvider = (key: string): string => {
  return SMSProvider[key as keyof typeof SMSProvider] || key;
};

export enum RepeatFrequency {
  NO = "None",
  DA = "Daily",
  WE = "Weekly",
  MO = "Monthly",
}

interface MsgContent {
  [key: string]: any;
}

export type MessageObj = {
  staticGroups: Array<string>;
  dynamicGroups: Array<{ from_range: string; to_range: string }>;
  messageInfo: MsgConfig;
  // We used below fields just for preview.
  recieverInfo?: [];
  status?: MessageStatus;
};

export type MessageStatusDetail = {
  status: MessageStatus;
  count: number;
};

export type ListMessageObj = {
  uniqueKey: string;
  recivers: Array<string>;
  futureReceivers: Array<string>;
  message: string;
  msgStatus: MessageStatus;
  receiversStatuses: Array<MessageStatusDetail>;
  sendTime: string;
  repeatFrequency: number;
  note?: string;
  smsProvider: SMSProvider;
  repeatFrequencyType?: RepeatFrequency;
};

export type MsgConfig = {
  provider: SMSProvider;
  textMessage: MsgContent | null;
  firstSendDate: Date | null;
  repeatFrequencyType: RepeatFrequency;
  repeatFrequency: number;
  repeatForNewContactsOnly: boolean;
  firstFormattedDate?: string;
  note?: string;
  endDate?: Date; // Formatted date
  endFormattedDate?: string;
  stoppedDate?: Date;
};

export type MsgTableSortConfig = {
  column: string;
  sortType: "Ascending" | "Descending";
};

export type RecipientObj = {
  id: string;
  name: string;
  phone: string;
};

export type ReceiverListObj = {
  uniqueKey: string;
  receiver: string;
  language: string;
  status: ReceiverDeliveryStatus;
  sendTime: string;
  smsProvider: SMSProvider;
};

export const getRepeatFrequencyByValue = (value: string) => {
  return (Object.values(RepeatFrequency) as string[]).includes(value)
    ? (Object.entries(RepeatFrequency).find(([key, val]) => val === value)?.[0] as RepeatFrequency)
    : RepeatFrequency.NO;
};

export const getSMSProviderByValue = (value: string) => {
  return (Object.values(SMSProvider) as string[]).includes(value)
    ? (Object.entries(SMSProvider).find(([key, val]) => val === value)?.[0] as SMSProvider)
    : SMSProvider.TO;
};

export const convertMsgStatusToDDItem = (defaultItems?: Array<IdValueType>) => {
  const result: IdValueType[] = defaultItems ? [...defaultItems] : [];
  const msgStatuses: IdValueType[] = Object.values(MessageStatus)
    .filter((value) => isNaN(Number(value)))
    .filter((value) => value !== MessageStatus.NO && value !== MessageStatus.DS && value !== MessageStatus.NN)
    .map((msgStatus) => ({ id: msgStatus, name: msgStatus, type: [] }));

  result.push(...msgStatuses);
  return result;
};

export const convertReceiverDeliveryStatusToDDItem = (defaultItems?: Array<IdValueType>) => {
  const result: IdValueType[] = defaultItems ? [...defaultItems] : [];
  const rcvDeliveryStatuses: IdValueType[] = Object.values(ReceiverDeliveryStatus)
    .filter((value) => isNaN(Number(value)))
    .filter((value) => value !== ReceiverDeliveryStatus.NO && value !== ReceiverDeliveryStatus.FI)
    .map((msgStatus) => ({ id: msgStatus, name: msgStatus, type: [] }));

  result.push(...rcvDeliveryStatuses);
  return result;
};

export const convertSmsProviderToDDItem = (defaultItems?: Array<IdValueType>) => {
  const result: IdValueType[] = defaultItems ? [...defaultItems] : [];
  const msgStatuses: IdValueType[] = Object.values(SMSProvider)
    .filter((value) => isNaN(Number(value)))
    .map((msgStatus) => ({ id: msgStatus, name: msgStatus, type: [] }));

  result.push(...msgStatuses);
  return result;
};
